<template>
	<div>
    <form-filter @form:changed="formChanged"/>

    <div>
      <nav>
        <ul class="pagination justify-content-center">
          <li class="page-item" :class="{ disabled: !canSetOffset(-limit) }"><a class="page-link" href="#" @click="setOffset(-limit)">&laquo;</a></li>
          <li class="page-item disabled"><a class="page-link" href="#">{{ (offset / limit) + 1 }}</a></li>
          <li class="page-item" :class="{ disabled: !canSetOffset(limit) }"><a class="page-link" href="#" @click="setOffset(limit)">&raquo;</a></li>
        </ul>
      </nav>
    </div>

		<table class="table table-sm">
			<thead>
				<tr>
					<th>Проект</th>
					<th>Задача</th>
					<th>Подзадача</th>
					<th>Начало</th>
					<th>Конец</th>
					<th>Длительность</th>
          <th>Действия</th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="item in items" :key="item.id">
					<td class="title-caped">
            <router-link :to="{ name: 'ProjectView', params: { id: item.project_id } }">{{ item.project.title }}</router-link>
          </td>
					<td class="title-caped">
            <router-link v-if="item.task_id" :to="{ name: 'TaskView', params: { id: item.project_id, task_id: item.task_id } }">{{ item.task.title }}</router-link>
            <span v-else>-</span>
          </td>
					<td class="title-caped">
            <router-link v-if="item.subtask_id" :to="{ name: 'TaskView', params: { id: item.project_id, task_id: item.task_id } }">{{ item.subtask.title }}</router-link>
            <span v-else>-</span>
          </td>
					<td>{{ format_date(item.time_start) }}</td>
					<td>{{ format_date(item.time_finish) }}</td>
					<td>{{ duration(item.time_start, item.time_finish) }}</td>
          <td>
          </td>
				</tr>
			</tbody>
		</table>

    <div>
      <nav>
        <ul class="pagination justify-content-center">
          <li class="page-item" :class="{ disabled: !canSetOffset(-limit) }"><a class="page-link" href="#" @click="setOffset(-limit)">&laquo;</a></li>
          <li class="page-item disabled"><a class="page-link" href="#">{{ (offset / limit) + 1 }}</a></li>
          <li class="page-item" :class="{ disabled: !canSetOffset(limit) }"><a class="page-link" href="#" @click="setOffset(limit)">&raquo;</a></li>
        </ul>
      </nav>
    </div>

    <timer-modal ref="timer_form_modal" :timer="timer_modal_data" />
	</div>
</template>

<script>
/* 
    TODO
    get data from the form, get timers, show them in the table sorted
*/
import log from '@/plugins/log';
import dayjs from '@/plugins/day'
import FormFilter from '@/components/timer/FormFilter';
import TimerModal from '@/components/modal/TimerForm';

export default {
	name: 'TimerList',
	data: () => {
		return {
      loading: false,
			items: null,
      limit: 50,
      offset: 0,
			form: {
				project_id: null,
				task_id: null,
        subtask_id: null
			},
      timer_modal_data: {}
		};
	},
	async mounted() {
		this.form.project_id = this.$route.params.project_id || null;
		this.form.task_id = this.$route.params.task_id || null;
    this.form.subtask_id = this.$route.params.subtask_id || null;
	},
	methods: {
    format_date(date) {
			return date ? dayjs(date).format('DD.MM HH:mm') : 'live';
		},
    canSetOffset(change) {
      if(change < 0 && this.offset + change < 0) return false;
      if(change > 0 && this.items?.length < this.limit) return false;
      return true;
    },
    async setOffset(change) {
      if(this.loading) return;

      this.offset += change;
      await this.data()
    },
    duration(start, finish) {
      return start && finish ? dayjs.duration(dayjs(finish).diff(start)).format('HH:mm:ss') : 'live';
    },
    formChanged(newForm) {
      this.form = Object.assign({}, newForm);
      this.data()
    },
		async data() {
      this.loading = true;

      let where = {};
      if(this.form.project !== null && this.form.project !== 'null') where.project_id = { _eq: this.form.project }
      if(this.form.task !== null && this.form.task !== 'null') where.task_id = { _eq: this.form.task }
      if(this.form.subtask !== null && this.form.subtask !== 'null') where.subtask_id = { _eq: this.form.subtask }

			var [err, timers] = await this.$orm.query({
				timer: {
					fragment: 'extended',
          where,
          limit: this.limit,
          offset: this.offset,
          order_by: {
            time_start: 'desc'
          }
				},
			});
			if (err) {
				log.error('/views/timer/view', 'methods.data', '$orm.query', err);
				return $.NotificationApp.send('Ошибка', 'Не получается отправить запрос', 'top-right', '', 'error');
			}
			log.debug('/views/timer/view', 'methods.data', '$orm.query', timers);
			this.items = timers;

      this.loading = false;
		},
	},
	components: {
		FormFilter,
    TimerModal
	},
};
</script>

<style>
.title-caped {
  max-width: 20ch;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
</style>
