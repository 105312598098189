<template>
  <div ref="timer_form_modal" :id="hashtag" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered">
			<div class="modal-content">
				<div class="modal-body pt-1">

                </div>
            </div>
        </div>
  </div>
</template>

<script>
export default {
    name: 'TimerFormModal',
    props: ['timer'],
    data: () => {
        return {
            hashtag: 'timer-form-modal',
            _form: {
				title: '',
			},
            form: {},
            loading: false
        }
    },
    methods: {
        openModal() {
            $(this.$refs.timer_form_modal).modal('show')
        },
        closeModal() {
            $(this.$refs.timer_form_modal).modal('hide')
		},
    }
}
</script>

<style>

</style>