<template>
    <div class="form-group form-inline">
        <select class="custom-select my-1 mr-sm-2" id="inlineFormCustomSelectPref" v-model="form.project" :disabled="loading || !projects">
            <option value="null">Все проекты</option>
            <option v-for="project of projects" :key="project.id" :value="project.id">{{ project.title.substring(0, 30) }}</option>
        </select>

        <select class="custom-select my-1 mr-sm-2" id="inlineFormCustomSelectPref" v-model="form.task" :disabled="loading || !tasks">
            <option value="null">Все задачи</option>
            <option v-for="task of tasks" :key="task.id" :value="task.id">{{ task.title.substring(0, 30) }}</option>
        </select>

        <select class="custom-select my-1 mr-sm-2" id="inlineFormCustomSelectPref" v-model="form.subtask" :disabled="loading || !subtasks">
            <option value="null">Все подзадачи</option>
            <option v-for="subtask of subtasks" :key="subtask.id" :value="subtask.id">{{ subtask.title.substring(0, 30) }}</option>
        </select>
    </div>
</template>

<script>
import log from '@/plugins/log'

export default {
    /* 
        TODO
        form with date, project, task and subtask selectors
    */
    data: () => {
        return {
            initiated: false,
            loading: false,
            projects: null,
            tasks: null,
            subtasks: null,
            form: {
                project: null,
                task: null,
                subtask: null
            }
        }
    },
    async beforeMount() {
        this.form.project = this.$route.params && this.$route.params.project_id ? this.$route.params.project_id : null;
        await this.getProjects()
        
        this.form.task = this.$route.params && this.$route.params.task_id ? this.$route.params.task_id : null;
        await this.getTasks()

        this.form.subtask = this.$route.params && this.$route.params.subtask_id ? this.$route.params.subtask_id : null;
        await this.getSubTasks()

        this.initiated = true;
        this.$emit('form:changed', this.form)
    },
    methods: {
        async getProjects() {
            this.loading = true;

            var [err, projects] = await this.$orm.query({
                project: {
                    fragment: 'id_title',
                    order_by: { updated_at: 'desc' }
                },
            });
            if (err) {
                log.error('/components/timer/formfilter', 'methods.data', '$orm.query', err);
                return $.NotificationApp.send('Ошибка', 'Не получается отправить запрос', 'top-right', '', 'error');
            }
            log.debug('/components/timer/formfilter', 'methods.data', '$orm.query', projects);
            this.projects = projects;

            this.loading = false;
        },
        async getTasks() {
            if(!this.form.project || this.form.project === null || this.form.project === 'null') return;

            this.loading = true;

            var [err, tasks] = await this.$orm.query({
                task: {
                    fragment: 'id_title',
                    where: { project_id: { _eq: this.form.project } },
                    order_by: { updated_at: 'desc' }
                },
            });
            if (err) {
                log.error('/components/timer/formfilter', 'methods.data', '$orm.query', err);
                return $.NotificationApp.send('Ошибка', 'Не получается отправить запрос', 'top-right', '', 'error');
            }
            log.debug('/components/timer/formfilter', 'methods.data', '$orm.query', tasks);
            this.tasks = tasks;

            this.loading = false;
        },
        async getSubTasks() {
             if(!this.form.task || this.form.task === null || this.form.task === 'null') return;

            this.loading = true;

            var [err, subtasks] = await this.$orm.query({
                subtask: {
                    fragment: 'id_title',
                    where: { task_id: { _eq: this.form.task } },
                    order_by: { updated_at: 'desc' }
                },
            });
            if (err) {
                log.error('/components/timer/formfilter', 'methods.data', '$orm.query', err);
                return $.NotificationApp.send('Ошибка', 'Не получается отправить запрос', 'top-right', '', 'error');
            }
            log.debug('/components/timer/formfilter', 'methods.data', '$orm.query', subtasks);
            this.subtasks = subtasks;

            this.loading = false;
        }
    },
    watch: {
        'form.project': {
            async handler(value, oldValue) {
                if(!this.initiated) return;
                this.tasks = null;
                this.form.task = null;
                if(value === null || value === 'null' || oldValue === value) return;
                await this.getTasks()
            }
        },
        'form.task': {
            async handler(value, oldValue) {
                if(!this.initiated) return;
                this.subtasks = null;
                this.form.subtask = null;
                if(value === null || value === 'null' || oldValue === value) return;
                await this.getSubTasks()
            }
        },
        'form': {
            deep: true,
            handler (value) {
                if(!this.initiated) return;
                this.$emit('form:changed', value)
            }
        }
    }
}
</script>

<style>

</style>